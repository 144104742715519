import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './lightbox-wrapper.scss';

export default class LightboxWrapper extends Component {
    state = {
        photoIndex: 0,
        isOpen: false
    };

    open(photoIndex) {
        this.setState({photoIndex, isOpen: true});
    }

    render() {
        return (
            <div className="lightbox-wrapper">
                <swiper-container className="lightbox-wrapper__swiper"
                                  init={!!this.props.images.length}
                                  slides-per-view={5}
                                  space-between={5}
                                  grab-cursor={false}
                                  ref={ref => this.swiper = ref}>
                    {this.props.images.map((image, index) => (
                        <swiper-slide key={index}>
                            <img className="lightbox-wrapper__image"
                                 src={image}
                                 alt="Figure"
                                 onClick={() => this.open(index)}/>
                        </swiper-slide>
                    ))}
                </swiper-container>

                {this.state.isOpen && (
                    <Lightbox mainSrc={this.props.images[this.state.photoIndex]}
                              nextSrc={this.props.images[(this.state.photoIndex + 1) % this.props.images.length]}
                              prevSrc={this.props.images[(this.state.photoIndex + this.props.images.length - 1) % this.props.images.length]}
                              onCloseRequest={() => this.setState({isOpen: false})}
                              onImageLoad={() => {
                                  window.dispatchEvent(new Event('resize'));
                              }}
                              onMovePrevRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + this.props.images.length - 1) % this.props.images.length,
                                  })
                              }
                              onMoveNextRequest={() =>
                                  this.setState({
                                      photoIndex: (this.state.photoIndex + 1) % this.props.images.length,
                                  })
                              }
                    />
                )}
            </div>
        );
    }
}