import React, {Component} from 'react';
import Modal from 'react-modal';
import './modal-wrapper.scss';
import Button from "../Button/Button";

Modal.setAppElement('#root');
export default class ModalWrapper extends Component {
    static defaultProps = {
        title: '',
        onClose: () => {
        },
        onAccept: () => {
        },
        canAccept: false,
        isDisabled: false,
        sending: false,
    };


    render() {
        const acceptBtn = <Button extraClasses="is-primary"
                                  icon={`${this.props.sending ? 'sync-alt' : ''}`}
                                  disabled={this.props.isDisabled}
                                  onClick={this.props.onAccept}
                                  spin={this.props.sending}
                                  text={`${this.props.sending ? 'Por favor, espera…' : 'Aceptar'}`}
        />

        return (
            <div className="modal-wrapper" id="modalWrapper">
                <Modal {...this.props}
                       isOpen={this.props.open}
                       style={{
                           overlay: {padding: 0, zIndex: 100, borderRadius: '4px'},
                           content: {height: 'fit-content'}
                       }}
                       showCloseIcon={false}>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.title}</p>
                            <button className="delete"
                                    aria-label="close"
                                    onClick={this.props.onClose}
                            />
                        </header>
                        <section className="modal-card-body" style={{position: 'relative'}}>
                            {this.props.children}
                        </section>
                        <footer className="modal-card-foot">

                            {this.props.canAccept ? acceptBtn : null}
                            <button className="button"
                                    onClick={this.props.onClose}>
                                Cerrar
                            </button>
                        </footer>
                    </div>
                </Modal>
            </div>
        );
    }
}