import Tooltip from '@zippytech/react-toolkit/Tooltip';
import '@zippytech/react-toolkit/Tooltip/index.css';
import React, { Component } from 'react';

export default class TooltipWrapper extends Component {
    static defaultProps = {
        showDelay: 0,
        target: '.show-tooltip'
    };

    render() {
        return (
            <Tooltip showDelay={this.props.showDelay} target={this.props.target} />
        );
    }
}