import React, { Component } from 'react';
import Modal from 'react-modal';
import './modal-wrapper.scss';

Modal.setAppElement('#root');
export default class ModalDeleteProfileWrapper extends Component {
    static defaultProps = {
        title: '',
        onClose: () => {},
        onDeleteProfile: () => {}
    };

    render() {
        return (
            <div className="modal-wrapper" id="modalDeleteProfileWrapper" >
                <Modal {...this.props}
                    isOpen={this.props.open}
                       style={{overlay: {padding: 0, zIndex: 100, borderRadius: '4px'}, content: {height: 'fit-content'}}}
                       showCloseIcon={false}>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.title}</p>
                            <button className="delete"
                                    aria-label="close"
                                    onClick={this.props.onClose}
                            />
                        </header>
                        <section className="modal-card-body" style={{position: 'relative'}}>
                            {this.props.children}
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-danger"
                                    onClick={this.props.onDeleteProfile}>
                                Eliminar
                            </button>
                            <button className="button"
                                    onClick={this.props.onClose}>
                                Cerrar
                            </button>
                        </footer>
                    </div>
                </Modal>
            </div>
        );
    }
}