export const teeSizes = [
    {id: 1, label: 'XS', type: 'Adulto'},
    {id: 2, label: 'S', type: 'Adulto'},
    {id: 3, label: 'M', type: 'Adulto'},
    {id: 4, label: 'L', type: 'Adulto'},
    {id: 5, label: 'XL', type: 'Adulto'},
    {id: 6, label: 'XXL', type: 'Adulto'},
    {id: 7, label: '86cm (1 año)', type: 'Niño'},
    {id: 8, label: '92cm (2 años)', type: 'Niño'},
    {id: 9, label: '3 (3 años)', type: 'Niño'},
    {id: 10, label: '4', type: 'Niño'},
    {id: 11, label: '6', type: 'Niño'},
    {id: 12, label: '8', type: 'Niño'},
    {id: 13, label: '10', type: 'Niño'},
    {id: 14, label: '12', type: 'Niño'},
    {id: 15, label: '14', type: 'Niño'},
    {id: 16, label: '16', type: 'Niño'}
];

export const trouserSizes = [
    {id: 1, label: '34', type: 'Adulto'},
    {id: 2, label: '36', type: 'Adulto'},
    {id: 3, label: '38', type: 'Adulto'},
    {id: 4, label: '40', type: 'Adulto'},
    {id: 5, label: '42', type: 'Adulto'},
    {id: 6, label: '44', type: 'Adulto'},
    {id: 7, label: '46', type: 'Adulto'},
    {id: 8, label: '48', type: 'Adulto'},
    {id: 9, label: '50', type: 'Adulto'},
    {id: 10, label: '52', type: 'Adulto'},
    {id: 11, label: '54', type: 'Adulto'},
    {id: 12, label: '86cm (1 año)', type: 'Niño'},
    {id: 13, label: '92cm (2 años)', type: 'Niño'},
    {id: 14, label: '3 (3 años)', type: 'Niño'},
    {id: 15, label: '4', type: 'Niño'},
    {id: 16, label: '6', type: 'Niño'},
    {id: 17, label: '8', type: 'Niño'},
    {id: 18, label: '10', type: 'Niño'},
    {id: 19, label: '12', type: 'Niño'},
    {id: 20, label: '14', type: 'Niño'},
    {id: 21, label: '16', type: 'Niño'}
];

export const footwearSizes = [
    {id: 1, label: '35', type: 'Adulto'},
    {id: 2, label: '36', type: 'Adulto'},
    {id: 3, label: '37', type: 'Adulto'},
    {id: 4, label: '38', type: 'Adulto'},
    {id: 5, label: '39', type: 'Adulto'},
    {id: 6, label: '40', type: 'Adulto'},
    {id: 7, label: '41', type: 'Adulto'},
    {id: 8, label: '42', type: 'Adulto'},
    {id: 9, label: '43', type: 'Adulto'},
    {id: 10, label: '44', type: 'Adulto'},
    {id: 11, label: '45', type: 'Adulto'},
    {id: 12, label: '46', type: 'Adulto'},
    {id: 13, label: '47', type: 'Adulto'},
    {id: 14, label: '48', type: 'Adulto'},
    {id: 15, label: '23', type: 'Niño'},
    {id: 16, label: '24', type: 'Niño'},
    {id: 17, label: '25', type: 'Niño'},
    {id: 18, label: '26', type: 'Niño'},
    {id: 19, label: '27', type: 'Niño'},
    {id: 20, label: '28', type: 'Niño'},
    {id: 21, label: '29', type: 'Niño'},
    {id: 22, label: '30', type: 'Niño'},
    {id: 23, label: '31', type: 'Niño'},
    {id: 24, label: '32', type: 'Niño'},
    {id: 25, label: '33', type: 'Niño'},
    {id: 26, label: '34', type: 'Niño'}
];

export const spokenLanguages = [
    {id: 1, label: 'Español'},
    {id: 2, label: 'Inglés'},
    {id: 3, label: 'Francés'},
    {id: 4, label: 'Italiano'},
    {id: 5, label: 'Valenciano'},
    {id: 6, label: 'Catalán'},
    {id: 7, label: 'Gallego'},
    {id: 8, label: 'Euskera'}
];

export const hairColors = [
    {id: 1, label: 'Negro'},
    {id: 2, label: 'Castaño'},
    {id: 3, label: 'Rubio'},
    {id: 4, label: 'Pelirrojo'}
];

export const eyesColors = [
    {id: 1, label: 'Negro'},
    {id: 2, label: 'Marrón'},
    {id: 3, label: 'Azul'},
    {id: 4, label: 'Verde'}
];

export const ethnicOrigins = [
    {id: 0, label: 'Otra'},
    {id: 1, label: 'Caucásica'},
    {id: 2, label: 'Mestiza'},
    {id: 3, label: 'Africana'},
    {id: 4, label: 'Asiática'},
    {id: 5, label: 'Latina'},
    {id: 6, label: 'Gitana'},
    {id: 7, label: 'India'},
    {id: 8, label: 'Árabe'}
];

export const nationalities = [
    {id: 10, label: 'Argentina'},
    {id: 20, label: 'Afganistán'},
    {id: 30, label: 'Albania'},
    {id: 40, label: 'Alemania'},
    {id: 50, label: 'Andorra'},
    {id: 60, label: 'Angola'},
    {id: 70, label: 'Anguilla'},
    {id: 80, label: 'Antártida Argentina'},
    {id: 90, label: 'Antigua y Barbuda'},
    {id: 100, label: 'Antillas Holandesas'},
    {id: 110, label: 'Arabia Saudita'},
    {id: 120, label: 'Argelia'},
    {id: 130, label: 'Armenia'},
    {id: 140, label: 'Aruba'},
    {id: 150, label: 'Australia'},
    {id: 160, label: 'Austria'},
    {id: 170, label: 'Azerbaiján'},
    {id: 180, label: 'Bahamas'},
    {id: 190, label: 'Bahrain'},
    {id: 200, label: 'Bangladesh'},
    {id: 210, label: 'Barbados'},
    {id: 220, label: 'Bélgica'},
    {id: 230, label: 'Belice'},
    {id: 240, label: 'Benin'},
    {id: 250, label: 'Bhutan'},
    {id: 260, label: 'Bielorusia'},
    {id: 270, label: 'Bolivia'},
    {id: 280, label: 'Bosnia Herzegovina'},
    {id: 290, label: 'Botswana'},
    {id: 300, label: 'Brasil'},
    {id: 310, label: 'Brunei'},
    {id: 320, label: 'Bulgaria'},
    {id: 330, label: 'Burkina Faso'},
    {id: 340, label: 'Burundi'},
    {id: 350, label: 'Cabo Verde'},
    {id: 360, label: 'Camboya'},
    {id: 370, label: 'Camerún'},
    {id: 380, label: 'Canadá'},
    {id: 390, label: 'Chad'},
    {id: 400, label: 'Chile'},
    {id: 410, label: 'China'},
    {id: 420, label: 'Chipre'},
    {id: 430, label: 'Colombia'},
    {id: 440, label: 'Comoros'},
    {id: 450, label: 'Congo'},
    {id: 460, label: 'Corea del Norte'},
    {id: 470, label: 'Corea del Sur'},
    {id: 480, label: 'Costa de Marfil'},
    {id: 490, label: 'Costa Rica'},
    {id: 500, label: 'Croacia'},
    {id: 510, label: 'Cuba'},
    {id: 520, label: 'Darussalam'},
    {id: 530, label: 'Dinamarca'},
    {id: 540, label: 'Djibouti'},
    {id: 550, label: 'Dominica'},
    {id: 560, label: 'Ecuador'},
    {id: 570, label: 'Egipto'},
    {id: 580, label: 'El Salvador'},
    {id: 590, label: 'Em. Arabes Un.'},
    {id: 600, label: 'Eritrea'},
    {id: 610, label: 'Eslovaquia'},
    {id: 620, label: 'Eslovenia'},
    {id: 630, label: 'España'},
    {id: 640, label: 'Estados Unidos'},
    {id: 650, label: 'Estonia'},
    {id: 660, label: 'Etiopía'},
    {id: 670, label: 'Fiji'},
    {id: 680, label: 'Filipinas'},
    {id: 690, label: 'Finlandia'},
    {id: 700, label: 'Francia'},
    {id: 710, label: 'Gabón'},
    {id: 720, label: 'Gambia'},
    {id: 730, label: 'Georgia'},
    {id: 740, label: 'Ghana'},
    {id: 750, label: 'Gibraltar'},
    {id: 760, label: 'Grecia'},
    {id: 770, label: 'Grenada'},
    {id: 780, label: 'Groenlandia'},
    {id: 790, label: 'Guadalupe'},
    {id: 800, label: 'Guam'},
    {id: 810, label: 'Guatemala'},
    {id: 820, label: 'Guayana Francesa'},
    {id: 830, label: 'Guinea'},
    {id: 840, label: 'Guinea Ecuatorial'},
    {id: 850, label: 'Guinea-Bissau'},
    {id: 860, label: 'Guyana'},
    {id: 870, label: 'Haití'},
    {id: 880, label: 'Holanda'},
    {id: 890, label: 'Honduras'},
    {id: 900, label: 'Hong Kong'},
    {id: 910, label: 'Hungría'},
    {id: 920, label: 'India'},
    {id: 930, label: 'Indonesia'},
    {id: 940, label: 'Irak'},
    {id: 950, label: 'Irán'},
    {id: 960, label: 'Irlanda'},
    {id: 970, label: 'Islandia'},
    {id: 980, label: 'Islas Cayman'},
    {id: 990, label: 'Islas Cook'},
    {id: 1000, label: 'Islas Faroe'},
    {id: 1010, label: 'Islas Marianas del Norte'},
    {id: 1020, label: 'Islas Marshall'},
    {id: 1030, label: 'Islas Solomon'},
    {id: 1040, label: 'Islas Turcas y Caicos'},
    {id: 1050, label: 'Islas Vírgenes'},
    {id: 1060, label: 'Islas Wallis y Futuna'},
    {id: 1070, label: 'Israel'},
    {id: 1080, label: 'Italia'},
    {id: 1090, label: 'Jamaica'},
    {id: 1100, label: 'Japón'},
    {id: 1110, label: 'Jordania'},
    {id: 1120, label: 'Kazajstán'},
    {id: 1130, label: 'Kenya'},
    {id: 1140, label: 'Kirguistán'},
    {id: 1150, label: 'Kiribati'},
    {id: 1160, label: 'Kuwait'},
    {id: 1170, label: 'Laos'},
    {id: 1180, label: 'Lesotho'},
    {id: 1190, label: 'Letonia'},
    {id: 1200, label: 'Líbano'},
    {id: 1210, label: 'Liberia'},
    {id: 1220, label: 'Libia'},
    {id: 1230, label: 'Liechtenstein'},
    {id: 1240, label: 'Lituania'},
    {id: 1250, label: 'Luxemburgo'},
    {id: 1260, label: 'Macao'},
    {id: 1270, label: 'Macedonia'},
    {id: 1280, label: 'Madagascar'},
    {id: 1290, label: 'Malasia'},
    {id: 1300, label: 'Malawi'},
    {id: 1310, label: 'Mali'},
    {id: 1320, label: 'Malta'},
    {id: 1330, label: 'Marruecos'},
    {id: 1340, label: 'Martinica'},
    {id: 1350, label: 'Mauricio'},
    {id: 1360, label: 'Mauritania'},
    {id: 1370, label: 'Mayotte'},
    {id: 1380, label: 'México'},
    {id: 1390, label: 'Micronesia'},
    {id: 1400, label: 'Moldova'},
    {id: 1410, label: 'Mónaco'},
    {id: 1420, label: 'Mongolia'},
    {id: 1430, label: 'Montserrat'},
    {id: 1440, label: 'Mozambique'},
    {id: 1450, label: 'Myanmar'},
    {id: 1460, label: 'Namibia'},
    {id: 1470, label: 'Nauru'},
    {id: 1480, label: 'Nepal'},
    {id: 1490, label: 'Nicaragua'},
    {id: 1500, label: 'Níger'},
    {id: 1510, label: 'Nigeria'},
    {id: 1520, label: 'Noruega'},
    {id: 1530, label: 'Nueva Caledonia'},
    {id: 1540, label: 'Nueva Zelandia'},
    {id: 1550, label: 'Omán'},
    {id: 1570, label: 'Pakistán'},
    {id: 1580, label: 'Panamá'},
    {id: 1590, label: 'Papua Nueva Guinea'},
    {id: 1600, label: 'Paraguay'},
    {id: 1610, label: 'Perú'},
    {id: 1620, label: 'Pitcairn'},
    {id: 1630, label: 'Polinesia Francesa'},
    {id: 1640, label: 'Polonia'},
    {id: 1650, label: 'Portugal'},
    {id: 1660, label: 'Puerto Rico'},
    {id: 1670, label: 'Qatar'},
    {id: 1680, label: 'RD Congo'},
    {id: 1690, label: 'Reino Unido'},
    {id: 1700, label: 'República Centroafricana'},
    {id: 1710, label: 'República Checa'},
    {id: 1720, label: 'República Dominicana'},
    {id: 1730, label: 'Reunión'},
    {id: 1740, label: 'Rumania'},
    {id: 1750, label: 'Rusia'},
    {id: 1760, label: 'Rwanda'},
    {id: 1770, label: 'Sahara Occidental'},
    {id: 1780, label: 'Saint Pierre y Miquelon'},
    {id: 1790, label: 'Samoa'},
    {id: 1800, label: 'Samoa Americana'},
    {id: 1810, label: 'San Cristóbal y Nevis'},
    {id: 1820, label: 'San Marino'},
    {id: 1830, label: 'Santa Elena'},
    {id: 1840, label: 'Santa Lucía'},
    {id: 1850, label: 'Sao Tomé y Príncipe'},
    {id: 1860, label: 'Senegal'},
    {id: 1870, label: 'Serbia y Montenegro'},
    {id: 1880, label: 'Seychelles'},
    {id: 1890, label: 'Sierra Leona'},
    {id: 1900, label: 'Singapur'},
    {id: 1910, label: 'Siria'},
    {id: 1920, label: 'Somalia'},
    {id: 1930, label: 'Sri Lanka'},
    {id: 1940, label: 'Sudáfrica'},
    {id: 1950, label: 'Sudán'},
    {id: 1960, label: 'Suecia'},
    {id: 1970, label: 'Suiza'},
    {id: 1980, label: 'Suriname'},
    {id: 1990, label: 'Swazilandia'},
    {id: 2000, label: 'Taiwán'},
    {id: 2010, label: 'Uruguay'},
    {id: 2020, label: 'Ucrania'},
    {id: 2030, label: 'Venezuela'},
];

export const spanishProvinces = [
    {id: 0, label: "Otra"},
    {id: 46, label: "València"},
    {id: 3, label: "Alacant"},
    {id: 12, label: "Castelló"},
    {id: 4, label: "Almería"},
    {id: 11, label: "Cádiz"},
    {id: 14, label: "Córdoba"},
    {id: 18, label: "Granada"},
    {id: 21, label: "Huelva"},
    {id: 23, label: "Jaén"},
    {id: 29, label: "Málaga"},
    {id: 41, label: "Sevilla"},
    {id: 22, label: "Huesca"},
    {id: 44, label: "Teruel"},
    {id: 50, label: "Zaragoza"},
    {id: 33, label: "Asturias"},
    {id: 7, label: "Illes Balears"},
    {id: 35, label: "Las Palmas"},
    {id: 38, label: "Santa Cruz de Tenerife"},
    {id: 39, label: "Cantabria"},
    {id: 5, label: "Ávila"},
    {id: 9, label: "Burgos"},
    {id: 24, label: "León"},
    {id: 34, label: "Palencia"},
    {id: 37, label: "Salamanca"},
    {id: 40, label: "Segovia"},
    {id: 42, label: "Soria"},
    {id: 47, label: "Valladolid"},
    {id: 49, label: "Zamora"},
    {id: 2, label: "Albacete"},
    {id: 13, label: "Ciudad Real"},
    {id: 16, label: "Cuenca"},
    {id: 19, label: "Guadalajara"},
    {id: 45, label: "Toledo"},
    {id: 8, label: "Barcelona"},
    {id: 17, label: "Girona"},
    {id: 25, label: "Lleida"},
    {id: 43, label: "Tarragona"},
    {id: 6, label: "Badajoz"},
    {id: 10, label: "Cáceres"},
    {id: 15, label: "A Coruña"},
    {id: 27, label: "Lugo"},
    {id: 32, label: "Ourense"},
    {id: 36, label: "Pontevedra"},
    {id: 28, label: "Madrid"},
    {id: 30, label: "Murcia"},
    {id: 31, label: "Navarra"},
    {id: 1, label: "Araba/Álava"},
    {id: 48, label: "Bizkaia"},
    {id: 20, label: "Gipuzkoa"},
    {id: 26, label: "La Rioja"},
    {id: 51, label: "Ceuta"},
    {id: 52, label: "Melilla"}
];

export const genders = [
    {id: 1, label: 'Mujer'},
    {id: 2, label: 'Hombre'},
    {id: 3, label: 'No Binario'},
];

export const findOptionByProperty = (collection, findValue, addExtraSuffix = false, findKey = 'id', returnKey = 'label', extraReturnKey = 'type') => {
    if (!collection && !findValue) return;
    const result = collection.find(item => item[findKey] === findValue);

    return result
        ? `${result[returnKey]}${addExtraSuffix ? ` (${result[extraReturnKey]})` : ''}`
        : null;
};
