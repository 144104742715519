import React, { Component } from 'react';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';
import './sortable-list.scss';

const DragHandle = SortableHandle(() => (
    <div className="sortable-list__handle">
        <Icon icon="grip-lines" />
    </div>
));

const SortableItem = SortableElement(({value}) => (
    <div className="sortable-list__item">
        <DragHandle />
        <div className="sortable-list__item-content">
            {value.render()}
        </div>
    </div>
));

const SortableList = SortableContainer(({items}) => {
    return (
        <div className="sortable-list__list">
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
});

export default class SortableListWrapper extends Component {
    state = {
        items: []
    };

    static defaultProps = {
        lockAxis: 'y',
        lockToContainerEdges: true
    };

    static propTypes = {
        idProperty: PropTypes.string.isRequired
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const currentItems = +this.state.items.map(item => item.idImage).join('');
        const nextItems = +nextProps.items.map(item => item.idImage).join('');

        if (currentItems !== nextItems) {
            this.setState({items: nextProps.items});
        }
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(
            {
                items: arrayMove(this.state.items, oldIndex, newIndex)
            },
            () => {
                return this.state.items.map((item, index) => (
                    this.props.onSortEnd({
                        [this.props.idProperty]: item[this.props.idProperty],
                        order: index
                    })
                ));
            }
        );
    };

    render() {
        return (
            <div className="sortable-list">
                <SortableList items={this.state.items}
                              lockAxis={this.props.lockAxis}
                              lockToContainerEdges={this.props.lockToContainerEdges}
                              useDragHandle
                              onSortEnd={this.onSortEnd}
                />
            </div>
        );
    }
}