import React, { Component } from 'react';
import NotificationBoard from '@zippytech/react-toolkit/Notification';
import '@zippytech/react-toolkit/Notification/index.css';

export default class NotificationWrapper extends Component {
    render() {
        return (
            <NotificationBoard pinButton
                               zIndex={1001}
                               relativeToViewport
                               offset={{left: 5, top: 20, right: 20, bottom: 5}}
                               ref={ref => this.board = ref} />
        );
    }
}