import React from 'react';
import {Route, Routes} from 'react-router-dom';
import PrivateRoute from '../components/HOC/PrivateRoute';
import HomePage from '../components/_pages/HomePage/HomePage';
import LoginPage from '../components/_pages/LoginPage/LoginPage';
import ProfilesPage from '../components/_pages/ProfilesPage/ProfilesPage';
import CustomersPage from '../components/_pages/CustomersPage/CustomersPage';
import ProjectsPage from '../components/_pages/ProjectsPage/ProjectsPage';
import WorkbenchPage from '../components/_pages/WorkbenchPage/WorkbenchPage';
import UnauthorizedPage from '../components/_pages/UnauthorizedPage/UnauthorizedPage';
import Error404Page from '../components/_pages/Error404Page/Error404Page';
import ProjectPage from "../components/_pages/ProjectPage/ProjectPage";
import ProfilePage from "../components/_pages/ProfilePage/ProfilePage";
import CustomerPage from "../components/_pages/CustomerPage/CustomerPage";

const appRoutes =  () => (
    <Routes>
        <Route path="/login/" element={<LoginPage/>}/>

        <Route exact path='/' element={<PrivateRoute Component={HomePage}/>}/>

        <Route exact path='/unauthorized' element={<PrivateRoute Component={UnauthorizedPage}/>}/>

        <Route exact path='/workbench' element={<PrivateRoute Component={WorkbenchPage}/>}/>

        <Route exact path='/projects/:idProject' element={<PrivateRoute Component={ProjectPage}/>}/>

        <Route exact path='/projects/' element={<PrivateRoute Component={ProjectsPage}/>}/>

        <Route exact path='/profiles/:idProfile' element={<PrivateRoute Component={ProfilePage}/>}/>

        <Route exact path='/profiles/' element={<PrivateRoute Component={ProfilesPage}/>}/>

        <Route exact path='/customers/:idCustomer' element={<PrivateRoute Component={CustomerPage}/>}/>

        <Route exact path='/customers/' element={<PrivateRoute Component={CustomersPage}/>}/>

        <Route element={<Error404Page/>}/>
    </Routes>
);
export default appRoutes;