import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from './context/AppProvider';
import './App.scss';
import AppRoutes from './routes/AppRoutes';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

window.addEventListener('error', e => {
    if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
    }
});

export default class App extends Component {
    render() {
        return (
            <AppProvider>
                <Router>
                    <Fragment>
                        <AppRoutes />
                    </Fragment>
                </Router>
            </AppProvider>
        );
    }
}
