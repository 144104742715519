import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComboBoxWrapper from '../_wrappers/ComboBoxWrapper';

export default class Select extends Component {
    static defaultProps = {
        idProperty: 'id',
        displayProperty: 'label',
        options: [],
        clearIcon: true,
        name: undefined,
        required: false,
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = value => {
        this.props.onChange({name: this.props.name, value: value});
    };

    render() {
        return (
            <ComboBoxWrapper name={this.props.name}
                             value={this.props.value}
                             idProperty={this.props.idProperty}
                             displayProperty={this.props.displayProperty}
                             placeholder={this.props.placeholder}
                             dataSource={this.props.options}
                             autocomplete={false}
                             multiple={this.props.multiple}
                             clearIcon={this.props.clearIcon}
                             toggleIcon={true}
                             expandOnClick={true}
                             toggleExpandOnClick={true}
                             onOptionSelected={this.handleChange}
            />
        );
    }
}