import BaseService from './BaseService';

export default class ImageService extends BaseService {
    create(idProfile, imageFile) {
        const endpoint = `images/create`;

        const uploadData = new FormData();
        uploadData.append('name', idProfile);
        uploadData.append('alt', idProfile);
        uploadData.append('imageFile', imageFile);
        uploadData.append('idProfile', idProfile);

        console.log(imageFile);
        return this.fetch(`${this.API_URL}${endpoint}`,
            {
                method: 'POST',
                body: uploadData
            },
            undefined,
            {}
        ).then(response => response);
    }

    updateOrder(idImage, order) {
        const endpoint = `images/${idImage}/order/${order}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'PUT'})
            .then(response => response);
    }

    remove(idImage) {
        const endpoint = `images/${idImage}`;

        return this
            .fetch(`${this.API_URL}${endpoint}`, {method: 'DELETE'})
            .then(response => response);
    }
}