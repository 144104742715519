import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component {
    static defaultProps = {
        name: undefined,
        checked: undefined,
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = event => {
        this.props.onChange({name: this.props.name, value: event.target.checked});
    };

    render() {
        return (
            <label className="checkbox">
                <input className="mr-1"
                       type="checkbox"
                       name={this.props.name}
                       checked={this.props.checked}
                       onChange={this.handleChange}
                       value={this.props.value}
                       required={this.props.required}
                />
                {this.props.label
                    ? this.props.label
                    : null
                }
            </label>
        );
    }
}