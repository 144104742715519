export const isAdmin = user => {
    return user.hasOwnProperty('roles') && user.roles.some(role => role === 'ROLE_ADMIN');
};

export const isCustomer = user => {
    return user.hasOwnProperty('roles') && user.roles.some(role => role === 'ROLE_CUSTOMER');
};

export const isUser = user => {
    return !isAdmin(user) && !isCustomer(user);
};