import React, { Component } from 'react';
import './hero.scss';

export default class Hero extends Component {
    static defaultProps = {
        title: '',
        subtitle: ''
    };

    render() {
        return (
            <section className="hero is-light mb-3">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                {this.props.title
                                    ? <h1 className="title">
                                        {this.props.title}
                                    </h1>
                                    : null
                                }
                                {this.props.subtitle
                                    ? <h2 className="subtitle">
                                        {this.props.subtitle}
                                    </h2>
                                    : null
                                }
                            </div>
                            {this.props.children
                                ? <div className="column is-narrow">
                                    {this.props.children}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}