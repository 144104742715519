import React, { Component } from 'react';

export default class FieldWrapper extends Component {
    static defaultProps = {
        valid: true,
        validationMessage: 'Campo requerido',
        helpText: '',
        onChange: () => {}
    };

    render() {
        return (
            <div className="field">
                {this.props.label
                    ? <label className="label">{this.props.label}</label>
                    : null
                }

                {this.props.children}

                {!this.props.valid && this.props.validationMessage
                    ? <p className="help is-danger">{this.props.validationMessage}</p>
                    : null
                }
                {this.props.helpText
                    ? <p className="help">{this.props.helpText}</p>
                    : null
                }
            </div>
        );
    }
}