import React, { Component, Fragment } from 'react';
import MainNavigation from '../MainNavigation/MainNavigation';
import TooltipWrapper from '../_wrappers/TooltipWrapper';

export default class Layout extends Component {
    render() {
        return (
            <Fragment>
                <TooltipWrapper />
                <header>
                    <MainNavigation {...this.props} />
                </header>
                <main>
                    {this.props.children}
                </main>
                <footer>

                </footer>
            </Fragment>
        );
    }
}