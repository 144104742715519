import React, { Component } from 'react';

export default class SearchForm extends Component {
    state = {
        q: ''
    };

    static defaultProps = {
        placeholder: 'Buscar…',
        onSubmit: () => {}
    };

    handleChange = event => {
        this.setState({q: event.target.value});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(this.state.q);
    };

    handleClick = event => {
        console.log(event);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="control has-icons-left">
                    <input className="input is-medium"
                           type="text"
                           value={this.state.q}
                           placeholder={this.props.placeholder}
                           onChange={this.handleChange}
                    />
                    <span className="icon is-medium is-left">
                        <i className="fas fa-search" />
                    </span>
                </div>
            </form>
        );
    }
}