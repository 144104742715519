import React, { Component, Fragment } from 'react';
import { withLayout } from '../../HOC/WithLayout';
import { Link } from 'react-router-dom';
import { isAdmin, isCustomer, isUser } from '../../_utils/user';
import Icon from '../../Icon/Icon';
import {withRouter} from "../LoginPage/withRouter";

class UnauthorizedPage extends Component {
    render() {
        return (
            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column is-half is-offset-one-quarter">
                            <section className="card my-5">
                                <header className="card-header">
                                    <div className="card-header-title has-text-danger">
                                        <Icon icon="exclamation-triangle" text="Acceso no autorizado" />
                                        {this.props.location && this.props.location.state
                                            ? <span className="has-text-danger ml-1 has-text-weight-light">
                                                ({this.props.location.state})
                                            </span>
                                            : null
                                        }
                                    </div>
                                </header>
                                <div className="card-content p-2">
                                    <p className="mb-2">Prueba con lo siguiente:</p>
                                    <div className="buttons">
                                        {isAdmin(this.props.user) || isCustomer(this.props.user)
                                            ? <Link className="button is-primary" to="/projects/">
                                                Proyectos
                                            </Link>
                                            : null
                                        }
                                        {isAdmin(this.props.user)
                                            ? <Fragment>
                                                <Link className="button is-info" to="/profiles/">
                                                    Perfiles
                                                </Link>
                                                <Link className="button is-info" to="/customers/">
                                                    Clientes
                                                </Link>
                                            </Fragment>
                                            : null
                                        }
                                        {isUser(this.props.user)
                                            ? <Link className="button is-primary"
                                                    to={`profiles/${this.props.user.pid}`}>
                                                Edita tu perfil
                                            </Link>
                                            : null
                                        }
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default withRouter(withLayout(UnauthorizedPage));