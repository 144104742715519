import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './table-cell-link.scss';

export default class TableCellLink extends Component {
    static defaultProps = {
        to: '',
        preventDecoration: true
    };

    render() {
        return (
            <td className="table-cell">
                {this.props.to
                    ? <Link className={`table-cell__link ${this.props.preventDecoration ? 'table-cell__link--undecorated' : ''}`}
                          to={this.props.to}>
                        {this.props.children}
                    </Link>
                    : React.cloneElement(this.props.children, {className: 'table-cell__link'})
                }
            </td>
        );
    }
}