import React, {Component} from 'react';
import Input from '../Form/Input';
import FieldWrapper from '../Form/FieldWrapper';
import Select from '../Form/Select';
import {
    ethnicOrigins,
    eyesColors,
    footwearSizes,
    genders,
    hairColors,
    nationalities,
    spanishProvinces,
    spokenLanguages,
    teeSizes,
    trouserSizes
} from '../_utils/options';
import Checkbox from '../Form/Checkbox';
import TextArea from '../Form/TextArea';
import Calendar from '../Form/Calendar';
import ContentSection from '../ContentSection/ContentSection';
import Button from '../Button/Button';
import {isAdmin} from '../_utils/user';
import Icon from "../Icon/Icon";

export default class ProfileForm extends Component {
    state = {
        formData: {
            name: this.props.profile.name || null,
            surname: this.props.profile.surname || null,
            email: this.props.profile.email || null,
            birthday: this.props.profile.birthday || null,
            gender: this.props.profile.gender || null,
            address: this.props.profile.address || '',
            postalCode: this.props.profile.postalCode || '',
            place: this.props.profile.place || '',
            provinceId: this.props.profile.provinceId || 0,
            phone: this.props.profile.phone || '',
            nationality: this.props.profile.nationality || null,
            socialSecurityNumber: this.props.profile.socialSecurityNumber || '',
            identification: this.props.profile.identification || '',
            weight: this.props.profile.weight || '',
            height: this.props.profile.height || '',
            eyesColor: this.props.profile.eyesColor || null,
            hairColor: this.props.profile.hairColor || null,
            teeSize: this.props.profile.teeSize || null,
            trousersSize: this.props.profile.trousersSize || null,
            footwearSize: this.props.profile.footwearSize || null,
            ethnicOrigin: this.props.profile.ethnicOrigin || null,
            ethnicOriginOther: this.props.profile.ethnicOriginOther || '',
            spokenLanguages: this.props.profile.spokenLanguages || [],
            actor: this.props.profile.actor || false,
            model: this.props.profile.model || false,
            drivingLicense: this.props.profile.drivingLicense || false,
            tattoosOrScars: this.props.profile.tattoosOrScars || false,
            sport: this.props.profile.sport || false,
            sportOther: this.props.profile.sportOther || '',
            dietaryPreferences: this.props.profile.dietaryPreferences || '',
            acceptFiguration: this.props.profile.acceptFiguration || false,
            notes: this.props.profile.notes || '',
            bankAccount: this.props.profile.bankAccount || '',
            externalLink: this.props.profile.externalLink || '',
            instagram: this.props.profile.instagram || ''
        }
    };

    static defaultProps = {
        onUpdate: () => {
        }
    };

    handleChange = field => {
        const formData = Object.assign(this.state.formData, {[field.name]: field.value});
        this.setState({formData});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.onUpdate(this.state.formData);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <ContentSection title="Datos personales">
                    <div className="columns is-multiline">
                        <div className="column is-one-third">
                            <FieldWrapper label="Nombre">
                                <Input placeholder="Nombre"
                                       name="name"
                                       value={this.state.formData.name}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Apellidos">
                                <Input placeholder="Apellidos"
                                       name="surname"
                                       value={this.state.formData.surname}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="E-mail">
                                <Input placeholder="E-mail"
                                       name="email"
                                       type="email"
                                       disabled={!isAdmin(this.props.user)}
                                       value={this.state.formData.email}
                                       required
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Nacimiento"
                                          helpText={this.props.profile.hasOwnProperty('age') ? `${this.props.profile.age} años` : null}>
                                <Calendar placeholder="Nacimiento"
                                          name="birthday"
                                          value={new Date(this.state.formData.birthday)}
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Género">
                                <Select placeholder="Género"
                                        name="gender"
                                        clearIcon={false}
                                        value={this.state.formData.gender}
                                        options={genders}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Domicilio">
                                <Input placeholder="Domicilio"
                                       name="address"
                                       value={this.state.formData.address}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Cód. Postal">
                                <Input placeholder="Cód. Postal"
                                       name="postalCode"
                                       value={this.state.formData.postalCode}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Ciudad">
                                <Input placeholder="Ciudad"
                                       name="place"
                                       value={this.state.formData.place}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Provincia">
                                <Select placeholder="Provincia"
                                        name="provinceId"
                                        value={this.state.formData.provinceId}
                                        options={spanishProvinces}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Teléfono"
                                          helpText={/^\+?[1-9]\d{8,14}$/.test(this.state.formData.phone) ? null : `Formato de teléfono no compatible con Whatsapp`}
                            >
                                <div className="is-flex is-align-items-center is-gap-2">
                                    <div className="is-fullwidth is-flex-grow-1">
                                        <Input placeholder="Teléfono"
                                               name="phone"
                                               value={this.state.formData.phone}
                                               onChange={this.handleChange}
                                        />
                                    </div>
                                    {this.state.formData.phone && /^\+?[1-9]\d{8,14}$/.test(this.state.formData.phone) && (
                                        <a href={`https://wa.me/${this.state.formData.phone}`} target="_blank"
                                           rel="noreferrer"><Icon variant="fab" icon="whatsapp"/></a>
                                    )}
                                </div>

                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Nacionalidad">
                                <Select placeholder="Nacionalidad"
                                        name="nationality"
                                        value={this.state.formData.nationality}
                                        options={nationalities}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Núm. Seg. Social">
                                <Input placeholder="Núm. Seg. Social"
                                       name="socialSecurityNumber"
                                       value={this.state.formData.socialSecurityNumber}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Identificación">
                                <Input placeholder="Identificación"
                                       name="identification"
                                       value={this.state.formData.identification}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                </ContentSection>
                <ContentSection title="Aspecto">
                    <div className="columns is-multiline">
                        <div className="column is-one-third">
                            <FieldWrapper label="Altura"
                                          helpText="En cm">
                                <Input placeholder="Altura"
                                       name="height"
                                       value={this.state.formData.height}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Peso"
                                          helpText="En Kg">
                                <Input placeholder="Peso"
                                       name="weight"
                                       value={this.state.formData.weight}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Color de ojos">
                                <Select placeholder="Color de ojos"
                                        name="eyesColor"
                                        clearIcon={false}
                                        value={this.state.formData.eyesColor}
                                        options={eyesColors}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Color de pelo">
                                <Select placeholder="Color de pelo"
                                        name="hairColor"
                                        value={this.state.formData.hairColor}
                                        options={hairColors}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Talla de camiseta">
                                <Select placeholder="Talla de camiseta"
                                        name="teeSize"
                                        value={this.state.formData.teeSize}
                                        options={teeSizes}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Talla de pantalones">
                                <Select placeholder="Talla de pantalones"
                                        name="trousersSize"
                                        value={this.state.formData.trousersSize}
                                        options={trouserSizes}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Talla de calzado">
                                <Select placeholder="Talla de calzado"
                                        name="footwearSize"
                                        value={this.state.formData.footwearSize}
                                        options={footwearSizes}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Etnia">
                                <Select placeholder="Etnia"
                                        name="ethnicOrigin"
                                        value={this.state.formData.ethnicOrigin}
                                        options={ethnicOrigins}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Etnia (otra)">
                                <Input placeholder="Otra etnia"
                                       name="ethnicOriginOther"
                                       value={this.state.formData.ethnicOriginOther}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                </ContentSection>
                <ContentSection title="Capacidades">
                    <div className="columns is-multiline">
                        <div className="column is-one-third">
                            <FieldWrapper label="Idiomas">
                                <Select placeholder="Idiomas"
                                        name="spokenLanguages"
                                        value={
                                            this.state.formData.spokenLanguages &&
                                            this.state.formData.spokenLanguages.map(language => {
                                                return language.hasOwnProperty('idLanguage')
                                                    ? language.idLanguage
                                                    : language;
                                            })
                                        }
                                        multiple
                                        options={spokenLanguages}
                                        onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Actor" helpText="Deber tener reel y/o trabajos profesionales">
                                <Checkbox name="actor"
                                          checked={this.state.formData.actor}
                                          label="Actor profesional"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Modelo" helpText="Debes tener Book y/o trabajos profesionales">
                                <Checkbox name="model"
                                          checked={this.state.formData.model}
                                          label="Modelo profesional"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Licencia">
                                <Checkbox name="drivingLicense"
                                          checked={this.state.formData.drivingLicense}
                                          label="Carnet de conducir"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Tatuajes o cicatrices">
                                <Checkbox name="tattoosOrScars"
                                          checked={this.state.formData.tattoosOrScars}
                                          label="Con tatuajes o cicatrices"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Deportista">
                                <Checkbox name="sport"
                                          checked={this.state.formData.sport}
                                          label="Practica deporte"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Deportes"
                                          helpText="Deportes (separados por comas)">
                                <Input placeholder="Deportes"
                                       name="sportOther"
                                       value={this.state.formData.sportOther}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Trabajos de figuración">
                                <Checkbox name="acceptFiguration"
                                          checked={this.state.formData.acceptFiguration}
                                          label="Acepta trabajos de figuración"
                                          onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Alimentación">
                                <TextArea placeholder="Alimentación"
                                       name="dietaryPreferences"
                                       value={this.state.formData.dietaryPreferences}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                </ContentSection>
                <ContentSection title="Otros">
                    <div className="columns is-multiline">
                        <div className="column is-one-third">
                            <FieldWrapper label="Número de cuenta">
                                <Input placeholder="Número de cuenta"
                                       name="bankAccount"
                                       value={this.state.formData.bankAccount}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Link externo (web, videobook, etc.)">
                                <Input placeholder="URL del link"
                                       name="externalLink"
                                       value={this.state.formData.externalLink}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-one-third">
                            <FieldWrapper label="Instagram">
                                <Input placeholder="Por ejemplo @usuario"
                                       name="instagram"
                                       value={this.state.formData.instagram}
                                       onChange={this.handleChange}
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                    {isAdmin(this.props.user)
                        ? <div className="columns">
                            <div className="column">
                                <FieldWrapper label="Notas">
                                    <TextArea placeholder="Notas u otras observaciones"
                                              name="notes"
                                              value={this.state.formData.notes}
                                              onChange={this.handleChange}
                                    />
                                </FieldWrapper>
                            </div>
                        </div>
                        : null
                    }
                </ContentSection>
                <div className="columns">
                    <div className="column">
                        <Button icon={`${this.props.sending ? 'sync-alt' : ''}`}
                                spin={this.props.sending}
                                disabled={!this.props.userCanEdit || this.props.sending}
                                extraClasses="is-primary is-medium"
                                type="submit"
                                text={`${this.props.sending ? 'Por favor, espera…' : 'Actualizar datos'}`}
                        />
                    </div>
                </div>
            </form>
        );
    }
}
