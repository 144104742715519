import React, { Component } from 'react';
import { DateInput } from '@zippytech/react-toolkit';
import '@zippytech/react-toolkit/Calendar/index.css';

export default class DateInputWrapper extends Component {
    static defaultProps = {
        dateFormat: 'DD-MM-YYYY',
        onChange: () => {}
    };

    handleChange = date => {
        this.props.onChange(date);
    };

    render() {
        return (
            <DateInput style={{width: '100%'}}
                       dateFormat={this.props.dateFormat}
                       value={this.props.value}
                       onChange={this.handleChange}
            />
        );
    }
}