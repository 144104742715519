import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateInputWrapper from '../_wrappers/DateInputWrapper';

export default class Calendar extends Component {
    static defaultProps = {
        name: undefined,
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = date => {
        this.props.onChange({name: this.props.name, value: date});
    };

    render() {
        return (
            <div className="control">
                <DateInputWrapper value={this.props.value}
                                  placeholder={this.props.placeholder}
                                  onChange={this.handleChange}
                />
            </div>
        );
    }
}