import React, { Component } from 'react';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';

export default class Input extends Component {
    static defaultProps = {
        type: 'text',
        name: undefined,
        iconLeft: '',
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = event => {
        this.props.onChange({name: this.props.name, value: event.target.value});
    };

    render() {
        return (
            <div className={`control ${this.props.iconLeft ? 'has-icons-left' : ''}`}>
                <input ref={ref => this.input = ref}
                       className="input"
                       type={this.props.type}
                       name={this.props.name}
                       value={this.props.value}
                       placeholder={this.props.placeholder}
                       onChange={this.handleChange}
                       required={this.props.required}
                       readOnly={this.props.readonly}
                       disabled={this.props.disabled}
                />
                {this.props.iconLeft
                    ? <span className="icon is-small is-left">
                        <Icon icon={this.props.iconLeft} />
                    </span>
                    : null
                }
            </div>
        );
    }
}