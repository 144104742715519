import React, { Component } from 'react';
import tropicalLogo from '../../../assets/images/tropical-logo.png';

export default class Error404Page extends Component {
    render() {
        return (
            <section className="section">
                <div className="container mt-3">
                    <div className="columns">
                        <div className="column is-one-third is-offset-one-third">
                            <figure className="image">
                                <img src={tropicalLogo} width="300"
                                     height="auto" alt="Tropical Casting" />
                            </figure>
                        </div>
                    </div>

                    <div className="columns is-centered">
                        <div className="column is-one-third">
                            Error 404
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}