import React, { Component } from 'react';
import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';

export default class TextArea extends Component {
    static defaultProps = {
        name: undefined,
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = event => {
        this.props.onChange({name: this.props.name, value: event.target.value});
    };

    render() {
        return (
            <div className={`control`}>
                <textarea className="textarea"
                          name={this.props.name}
                          value={this.props.value}
                          placeholder={this.props.placeholder}
                          onChange={this.handleChange}
                          required={this.props.required}
                />
                {this.props.iconLeft
                    ? <span className="icon is-small is-left">
                        <Icon icon={this.props.iconLeft} />
                    </span>
                    : null
                }
            </div>
        );
    }
}