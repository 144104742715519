export class ProfileDataAdapter {
    static fromUpdateForm(formData) {
        let adaptedData = {};

        if (formData.hasOwnProperty('name') && formData.name) {
            adaptedData.name = formData.name;
        }

        if (formData.hasOwnProperty('surname') && formData.surname) {
            adaptedData.surname = formData.surname;
        }

        if (formData.hasOwnProperty('email') && formData.email) {
            adaptedData.email = formData.email;
        }

        if (formData.hasOwnProperty('teeSize') && formData.teeSize) {
            adaptedData.teeSize = formData.teeSize;
        }

        if (formData.hasOwnProperty('eyesColor') && formData.eyesColor) {
            adaptedData.eyesColor = formData.eyesColor;
        }

        if (formData.hasOwnProperty('birthday') && formData.birthday) {
            adaptedData.birthday = formData.birthday;
        }

        if (formData.hasOwnProperty('identification') && formData.identification) {
            adaptedData.identification = formData.identification;
        }

        if (formData.hasOwnProperty('nationality') && formData.nationality) {
            adaptedData.nationality = formData.nationality;
        }

        if (formData.hasOwnProperty('socialSecurityNumber') && formData.socialSecurityNumber) {
            adaptedData.socialSecurityNumber = formData.socialSecurityNumber;
        }

        if (formData.hasOwnProperty('phone') && formData.phone) {
            adaptedData.phone = formData.phone;
        }

        if (formData.hasOwnProperty('address') && formData.address) {
            adaptedData.address = formData.address;
        }

        if (formData.hasOwnProperty('postalCode') && formData.postalCode) {
            adaptedData.postalCode = formData.postalCode;
        }

        if (formData.hasOwnProperty('place') && formData.place) {
            adaptedData.place = formData.place;
        }

        if (formData.hasOwnProperty('provinceId')) {
            adaptedData.provinceId = formData.provinceId ? formData.provinceId : 0;
        }

        if (formData.hasOwnProperty('height') && formData.height) {
            adaptedData.height = formData.height;
        }

        if (formData.hasOwnProperty('weight') && formData.weight) {
            adaptedData.weight = formData.weight;
        }

        if (formData.hasOwnProperty('ethnicOrigin') && formData.ethnicOrigin) {
            adaptedData.ethnicOrigin = formData.ethnicOrigin;
        }

        if (formData.hasOwnProperty('trousersSize') && formData.trousersSize) {
            adaptedData.trousersSize = formData.trousersSize;
        }

        if (formData.hasOwnProperty('footwearSize') && formData.footwearSize) {
            adaptedData.footwearSize = formData.footwearSize;
        }

        if (formData.hasOwnProperty('hairColor') && formData.hairColor) {
            adaptedData.hairColor = formData.hairColor;
        }

        if (formData.hasOwnProperty('drivingLicense')) {
            adaptedData.drivingLicense = formData.drivingLicense;
        }

        if (formData.hasOwnProperty('tattoosOrScars')) {
            adaptedData.tattoosOrScars = formData.tattoosOrScars;
        }

        if (formData.hasOwnProperty('actor')) {
            adaptedData.actor = formData.actor;
        }

        if (formData.hasOwnProperty('model')) {
            adaptedData.model = formData.model;
        }

        if (formData.hasOwnProperty('notes') && formData.notes) {
            adaptedData.notes = formData.notes;
        }

        if (formData.hasOwnProperty('ethnicOriginOther') && formData.ethnicOriginOther) {
            adaptedData.ethnicOriginOther = formData.ethnicOriginOther;
        }

        if (formData.hasOwnProperty('sport')) {
            adaptedData.sport = formData.sport;
        }

        if (formData.hasOwnProperty('sportOther') && formData.sportOther) {
            adaptedData.sportOther = formData.sportOther;
        }

        if (formData.hasOwnProperty('dietaryPreferences') && formData.dietaryPreferences) {
            adaptedData.dietaryPreferences = formData.dietaryPreferences;
        }

        if (formData.hasOwnProperty('acceptFiguration')) {
            adaptedData.acceptFiguration = formData.acceptFiguration;
        }

        if (formData.hasOwnProperty('gender')) {
            adaptedData.gender = formData.gender ? formData.gender : null;
        }

        if (formData.hasOwnProperty('spokenLanguages') && formData.spokenLanguages && formData.spokenLanguages.length) {
            const languageIds = formData.spokenLanguages[0].hasOwnProperty('name')
                ? formData.spokenLanguages.map(spokenLanguage => spokenLanguage.name)
                : formData.spokenLanguages;
            adaptedData.spokenLanguages = languageIds.join(',');
        }

        if (formData.hasOwnProperty('bankAccount') && formData.bankAccount) {
            adaptedData.bankAccount = formData.bankAccount;
        }

        if (formData.hasOwnProperty('externalLink') && formData.externalLink) {
            adaptedData.externalLink = formData.externalLink;
        }

        if (formData.hasOwnProperty('instagram') && formData.instagram) {
            adaptedData.instagram = formData.instagram;
        }

        return adaptedData;
    }
}
