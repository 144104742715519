import React, {Component, Fragment} from 'react';
import './modal-wrapper.scss';
import ModalWrapper from "./ModalWrapper";
import './modal-add-to-project-bulk.scss';
import {AppContext} from "../../context/AppProvider";
import DateInputWrapper from "../_wrappers/DateInputWrapper";
import NotificationWrapper from "../_wrappers/NotificationWrapper";
import Button from "../Button/Button";

export default class ModalShareProject extends Component {
    static defaultProps = {
        isOpen: false,
        onClose: () => {
        },
        projectId: null,
    };

    static contextType = AppContext;

    getExpirationDefaultDate() {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() + 7);
        return newDate;
    }

    state = {
        expirationDate: this.getExpirationDefaultDate(),
        url: null,
        loading: false,
        isEditingDate: false,
    };

    async componentDidMount() {
        const project = await this.getProject();
        if (!project.shareUrl || !project.expirationShareDate) {
            await this.generateShareData();
        } else {
            this.setState({
                "expirationDate": new Date(project.expirationShareDate),
                "url": project.shareUrl,
                "loading": false,
                "isEditingDate": false,
            })
        }
    }

    async getProject() {
        return this.context.services.project
            .findById(this.props.projectId);
    }

    generateShareData() {
        if (!this.props.projectId) return;
        this.setState({loading: true});
        this.context.services.project
            .share(this.props.projectId, this.state.expirationDate.toISOString())
            .then(async () => {
                const project = await this.getProject();
                this.setState({
                    "expirationDate": new Date(project.expirationShareDate),
                    "url": project.shareUrl,
                    "loading": false,
                    "isEditingDate": false,
                })
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error al obtener los datos de compartición proyecto',
                    content: reason.message,
                    className: 'danger'
                });
            });
    }

    activeEdition() {
        if (this.state.loading) return;
        this.setState({isEditingDate: true});
    }

    onEditDate() {
        this.generateShareData();
    }

    onDateSelectionChange(date) {
        if (!date) return;
        this.setState({expirationDate: this.parseDate(date)})
    }

    /**
     * Get date string UTC format: 'DD-MM-YYYY' and return a Date object
     * @param date
     */
    parseDate(date) {
        if (!date) return;
        const parts = date.split('-');
        return new Date(Date.UTC(parts[2], parts[1] - 1, parts[0]));
    }

    render() {
        return (
            <Fragment>
                <ModalWrapper title="Compartir PDF del proyecto"
                              open={this.props.isOpen}
                              onClose={this.props.onClose}>
                    <div>
                        <p className="mb-2 has-text-grey"> Cada vez que cambies la fecha de caducidad cambiará la URL de
                            compartición. La anterior URL quedará invalidada.</p>
                        {this.state.isEditingDate
                            ?
                            <div>
                                <DateInputWrapper
                                    value={this.state.expirationDate}
                                    placeholder={this.props.placeholder}
                                    onChange={(date) => this.onDateSelectionChange(date)}/>

                                <p className="mt-2 has-text-grey"> Recuerda, si aceptas la anterior URL <b>quedará
                                    invalidada.</b></p>
                                <Button extraClasses="is-primary is-small mt-2"
                                        spin={this.state.loading}
                                        disabled={this.state.loading || !this.state.expirationDate}
                                        text={`${this.state.loading ? '' : 'Aceptar'}`}
                                        onClick={() => this.onEditDate()}
                                />
                                <Button extraClasses="is-small mt-2 ml-2"
                                        spin={this.state.sending}
                                        text={`${this.state.loading ? '' : 'Cancelar'}`}
                                        onClick={() => this.setState({isEditingDate: false})}
                                />
                            </div>
                            :
                            <div>

                                <p className="mb-2 has-text-grey">
                                    <b>URL del proyecto:</b>
                                    <a className="ml-1" href={this.state.url} target="_blank"
                                       rel="noreferrer">{this.state.url}</a>
                                </p>
                                <p className="mb-1 has-text-grey">
                                    <b>Fecha de caducidad: </b>
                                    {this.state.expirationDate.toLocaleDateString()}
                                    <span className="ml-2 cursor-pointer">
                                          <Button icon={`${this.state.loading ? '' : 'pencil-alt'}`}
                                                  spin={this.state.sending}
                                                  disabled={this.state.loading}
                                                  extraClasses="is-primary is-small"
                                                  text={`${this.state.loading ? '' : 'Editar'}`}
                                                  onClick={() => this.activeEdition()}
                                          />
                                </span>

                                </p>
                            </div>

                        }
                    </div>
                </ModalWrapper>
                <NotificationWrapper ref={ref => this.notification = ref}/>
            </Fragment>
        );
    }
}