import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Breadcrumb extends Component {
    render() {
        return (
            <nav className="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    {this.props.model.map((item, index) => (
                        <li key={index} className={item.active ? 'is-active' : ''}>
                            <Link to={item.link}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }
}