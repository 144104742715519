import React, {Component, Fragment} from 'react';
import {AppContext} from '../../../context/AppProvider';
import Pagination from '../../Pagination/Pagination';
import {withLayout} from '../../HOC/WithLayout';
import Hero from '../../Hero/Hero';
import LoadMaskWrapper from '../../_wrappers/LoadMaskWrapper';
import Moment from 'react-moment';
import Button from '../../Button/Button';
import ProjectForm from '../../ProjectForm/ProjectForm';
import FormModalWrapper from '../../ModalWrapper/FormModalWrapper';
import NotificationWrapper from '../../_wrappers/NotificationWrapper';
import SearchForm from '../../SearchForm/SearchForm';
import {isAdmin} from '../../_utils/user';
import saveAs from 'file-saver';
import Toggle from '../../Toggle/Toggle';
import TableCellLink from '../../TableCellLink/TableCellLink';
import SearchFilters from '../../SearchFilters/SearchFilters';
import {withRouter} from "../LoginPage/withRouter";
import moment from "moment/moment";

class ProjectsPage extends Component {
    state = {
        projects: [],
        pagination: {},
        loading: true,
        modalAddProjectOpen: false,
        modalUpdateProjectOpen: false,
        currentEditProject: null,
        showOnlyActive: true,
        exportingCSV: false,
    };

    static contextType = AppContext;

    componentDidMount() {
        this.findProjects();
    }

    findProjects(page = 1, q = null, active = this.state.showOnlyActive) {
        this.context.services.project
            .search(page, q ?? '', active)
            .then(response => {
                if (!response) {
                    this.setState({
                        projects: [],
                        pagination: {},
                        loading: false
                    });

                    return;
                }

                this.setState({
                    projects: response.items,
                    pagination: {
                        pageRange: response.pageRange,
                        currentPage: response.currentPage,
                        itemsPerPage: response.itemsPerPage,
                        totalItems: response.totalItems
                    },
                    loading: false
                });
            }).catch(reason => {
            console.error(reason);
            this.props.navigate({pathname: '/unauthorized', state: reason.message});
        });
    }

    handlePageChange = page => {
        this.findProjects(page);
    };

    openAddProjectModal = () => {
        this.setState({modalAddProjectOpen: true});
    };

    closeAddProjectModal = () => {
        this.setState({modalAddProjectOpen: false});
    };

    openUpdateProjectModal = project => {
        this.setState({
            currentEditProject: {
                idProject: project.idProject,
                name: project.name
            },
            modalUpdateProjectOpen: true
        });
    };

    getProjectLength = project => {
        return project.users
            ? project.users.map(user => user.roles).filter(role => role.toString() === 'ROLE_USER').length
            : 0
    };

    exportCSV = project => {
        this.setState({exportingCSV: true});
        this.context.services.project
            .exportCSV(project.idProject)
            .then(response => {
                const dateString = moment().format('DD-MM-YYYY-HH-mm-ss');
                const filename = `proyecto_${project.idProject}_todos_${dateString}.csv`;
                saveAs(response, filename);
                this.setState({exportingCSV: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error descargando PDF',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({exportingCSV: false});
            });
    };

    closeUpdateProjectModal = () => {
        this.setState({
            currentEditProject: null,
            modalUpdateProjectOpen: false
        });
    };

    addProject = formData => {
        this.setState({sending: true});
        this.context.services.project
            .create(formData.name)
            .then(() => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'Nuevo proyecto creado',
                    className: 'success'
                });
                this.closeAddProjectModal();
                this.findProjects();
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error creando proyecto',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    updateProject = formData => {
        this.setState({sending: true});
        this.context.services.project
            .update(formData.idProject, formData.name)
            .then(() => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: 'El proyecto ha sido actualizado',
                    className: 'success'
                });
                this.closeUpdateProjectModal();
                this.findProjects();
                this.setState({sending: false});
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error actualizando proyecto',
                    content: reason.message,
                    className: 'danger'
                });
                this.setState({sending: false});
            });
    };

    toggleActive = (idProject, checked) => {
        this.context.services.project
            .toggleActive(idProject, !checked)
            .then(() => {
                this.notification.board.addNotification({
                    title: '¡Todo bien!',
                    content: `El proyecto ha sido ${!checked ? 'activado' : 'desactivado'}`,
                    className: `${!checked ? 'success' : 'warning'}`
                });
                this.findProjects();
            })
            .catch(reason => {
                this.notification.board.addNotification({
                    title: 'Error actualizando proyecto',
                    content: reason.message,
                    className: 'danger'
                });
            });
    };

    toggleShowActiveProjects = showOnlyActive => {
        this.setState({showOnlyActive: !showOnlyActive}, () => {
            this.findProjects(1, null, this.state.showOnlyActive)
        });
    };

    render() {
        return (
            <Fragment>
                <Hero title="Listado de proyectos">
                    {isAdmin(this.props.user)
                        ? <Button extraClass="is-outlined"
                                  text="Nuevo Proyecto"
                                  icon="plus"
                                  onClick={this.openAddProjectModal}
                        />
                        : null
                    }
                </Hero>
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-half is-offset-one-quarter">
                                <SearchFilters>
                                    <SearchForm placeholder="Buscar proyectos por nombre…"
                                                onSubmit={q => this.findProjects(1, q)}
                                    />
                                    {isAdmin(this.props.user) &&
                                        <Toggle className="ml-1"
                                                checked={this.state.showOnlyActive}
                                                onChange={() => this.toggleShowActiveProjects(this.state.showOnlyActive)}>
                                            {this.state.showOnlyActive ? 'Solo activos' : 'Todos'}
                                        </Toggle>
                                    }
                                </SearchFilters>
                            </div>
                        </div>
                        <Pagination className="mb-2"
                                    {...this.state.pagination}
                                    onPageChange={this.handlePageChange}
                        />
                        <div className="responsive-table-wrapper">
                            <table className="table is-bordered is-hoverable is-fullwidth table--align-middle">
                                <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Perfiles asociados</th>
                                    <th>Fecha creación</th>
                                    {isAdmin(this.props.user) &&
                                        <Fragment>
                                            <th className="has-text-centered">Activo</th>
                                            <th style={{textAlign: 'center'}}>Acciones</th>
                                        </Fragment>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.projects.map(project => (
                                    <tr key={project.idProject}>
                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                            {project.name}
                                        </TableCellLink>
                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                            {this.getProjectLength(project)}
                                        </TableCellLink>
                                        <TableCellLink to={`/projects/${project.idProject}`}>
                                            <Moment format="DD/MM/YYYY HH:mm">{project.tmsCreated}</Moment>
                                        </TableCellLink>
                                        {isAdmin(this.props.user) &&
                                            <Fragment>
                                                <td className="has-text-centered">
                                                    <Toggle checked={project.active}
                                                            onChange={() => this.toggleActive(project.idProject, project.active)}
                                                    />
                                                </td>
                                                <td style={{textAlign: 'center'}}>
                                                    {isAdmin(this.props.user) &&
                                                        <Button icon="edit"
                                                                extraClasses="is-small is-light"
                                                                text="Actualizar"
                                                                onClick={() => this.openUpdateProjectModal(project)}/>
                                                    }
                                                    {isAdmin(this.props.user) &&
                                                        <Button icon={`${this.state.exportingCSV ? 'sync-alt' : 'file-csv'}`}
                                                                spin={this.state.exportingCSV}
                                                                disabled={!this.getProjectLength(project)}
                                                                extraClasses="is-small is-light ml-1"
                                                                text={`${this.state.exportingCSV ? 'Exportando…' : 'Exportar CSV'}`}
                                                                onClick={() => this.exportCSV(project)}/>
                                                    }
                                                </td>
                                            </Fragment>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td className="has-text-grey" colSpan={1000}>
                                        {this.state.projects && this.state.projects.length
                                            ? `${this.state.projects.length} proyectos`
                                            : 'No se encuentran resultados…'
                                        }
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                        <Pagination className="mt-2"
                                    {...this.state.pagination}
                                    onPageChange={this.handlePageChange}
                        />
                    </div>
                </section>
                <LoadMaskWrapper visible={this.state.loading}
                                 backgroundLayerStyle={{position: 'fixed'}}>
                    <h3>Cargando proyectos…</h3>
                </LoadMaskWrapper>
                <FormModalWrapper title="Añade un proyecto"
                                  open={this.state.modalAddProjectOpen}
                                  onClose={this.closeAddProjectModal}>
                    <ProjectForm sending={this.state.sending}
                                 onSubmit={this.addProject}
                                 onCancel={this.closeAddProjectModal}
                    />
                </FormModalWrapper>
                <FormModalWrapper title="Edita el proyecto"
                                  open={this.state.modalUpdateProjectOpen}
                                  onClose={this.closeUpdateProjectModal}>
                    <ProjectForm sending={this.state.sending}
                                 onSubmit={this.updateProject}
                                 data={this.state.currentEditProject}
                                 onCancel={this.closeUpdateProjectModal}
                    />
                </FormModalWrapper>
                <NotificationWrapper ref={ref => this.notification = ref}/>
            </Fragment>
        );
    }
}

export default withRouter(withLayout(ProjectsPage));