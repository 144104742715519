import React, { Component } from 'react';
import FieldWrapper from '../Form/FieldWrapper';
import Input from '../Form/Input';
import Button from '../Button/Button';

export default class NewProfileForm extends Component {
    state = {
        formData: {
            name: this.props.data && this.props.data.hasOwnProperty('name') ? this.props.data.name : '',
            surname: this.props.data && this.props.data.hasOwnProperty('surname') ? this.props.data.surname : '',
            email: this.props.data && this.props.data.hasOwnProperty('email') ? this.props.data.email : '',
        },
        valid: false
    };

    static defaultProps = {
        onSubmit: () => {},
        onCancel: () => {},
    };

    componentDidMount() {
        this.validate(this.form);
    }

    handleChange = field => {
        const formData = Object.assign(this.state.formData, {[field.name]: field.value});
        this.setState({formData});
        this.validate(this.form);
    };

    handleCancel = event => {
        event.preventDefault();
        this.props.onCancel();
    };

    handleSubmit = event => {
        event.preventDefault();
        this.validate(this.form);
        this.props.onSubmit(this.state.formData);
    };

    validate(form) {
        this.setState({
            valid: form.checkValidity()
        });
    }

    render() {
        return (
            <form ref={ref => this.form = ref}
                  noValidate
                  onSubmit={this.handleSubmit}>
                <section className="modal-card-body" style={{position: 'relative'}}>
                    <div className="columns is-multiline">
                        <div className="column is-half">
                            <FieldWrapper label="Nombre"
                                          valid={this.name && this.name.input.validity.valid}
                                          validationMessage={this.name && this.name.input.validationMessage}>
                                <Input ref={ref => this.name = ref}
                                       placeholder="Nombre"
                                       name="name"
                                       value={this.state.formData.name}
                                       onChange={this.handleChange}
                                       required
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-half">
                            <FieldWrapper label="Apellidos"
                                          valid={this.surname && this.surname.input.validity.valid}
                                          validationMessage={this.surname && this.surname.input.validationMessage}>
                                <Input ref={ref => this.surname = ref}
                                       placeholder="Apellidos"
                                       name="surname"
                                       value={this.state.formData.surname}
                                       onChange={this.handleChange}
                                       required
                                />
                            </FieldWrapper>
                        </div>
                        <div className="column is-half">
                            <FieldWrapper label="E-mail"
                                          valid={this.email && this.email.input.validity.valid}
                                          validationMessage={this.email && this.email.input.validationMessage}>
                                <Input ref={ref => this.email = ref}
                                       placeholder="E-mail"
                                       name="email"
                                       type="email"
                                       value={this.state.formData.email}
                                       onChange={this.handleChange}
                                       required
                                />
                            </FieldWrapper>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <Button icon={`${this.props.sending ? 'sync-alt' : ''}`}
                            spin={this.props.sending}
                            disabled={this.props.sending || !this.state.valid}
                            extraClasses="is-success"
                            type="submit"
                            text={`${this.props.sending ? 'Por favor, espera…' : 'OK'}`}
                    />
                    <Button text="Cancelar"
                            onClick={this.handleCancel} />
                </footer>
            </form>
        );
    }
}