import React, { Component } from 'react';
import { RangeSlider } from '@zippytech/react-toolkit/Slider';
import '@zippytech/react-toolkit/Slider/index.css';

export default class RangeSliderWrapper extends Component {
    static defaultProps = {
        endValue: 100,
        onSlide: () => {}
    };

    handleChange = range => {
        this.props.onSlide(range);
    };

    render() {
        return (
            <RangeSlider style={{width: '100%'}}
                         value={this.props.range}
                         endValue={this.props.endValue}
                         onChange={this.handleChange} />
        );
    }
}