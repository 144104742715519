import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangeSliderWrapper from '../_wrappers/RangeSliderWrapper';

export default class Select extends Component {
    static defaultProps = {
        range: [],
        name: undefined,
        required: false,
        onChange: () => {}
    };

    static propTypes = {
        name: PropTypes.string.isRequired
    };

    handleChange = value => {
        this.props.onChange({name: this.props.name, value: value});
    };

    render() {
        return (
            <RangeSliderWrapper range={this.props.range}
                                endValue={this.props.endValue}
                                onSlide={this.handleChange} />
        );
    }
}