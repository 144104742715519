import React, { Component } from 'react';
import './content-section.scss';

export default class ContentSection extends Component {
    render() {
        return (
            <section className="content-section">
                {this.props.title
                    ? <header className="content-section__header">
                        <h1 className="content-section__title">{this.props.title}</h1>
                    </header>
                    : null
                }
                {this.props.children}
            </section>
        );
    }
}