import React, { Component } from 'react';
import LoadMask from '@zippytech/react-toolkit/LoadMask';
import '@zippytech/react-toolkit/LoadMask/index.css';

export default class LoadMaskWrapper extends Component {
    render() {
        return (
            <LoadMask {...this.props}>
                {this.props.children}
            </LoadMask>
        );
    }
}