import React, { Component, Fragment } from 'react';

export default class Icon extends Component {
    static defaultProps = {
        variant: 'fas',
        fw: false,
        spin: false,
        onClick: () => {}
    };

    render() {
        return (
            <Fragment>
                <i className={`${this.props.variant} fa-${this.props.icon} ${this.props.fw ? 'fa-fw' : ''} ${this.props.spin ? 'fa-spin' : ''}`}
                   aria-hidden="true"
                   onClick={this.props.onClick}
                />
                {this.props.text
                    ? <Fragment>&nbsp;{this.props.text}</Fragment>
                    : null
                }
            </Fragment>
        );
    }
}