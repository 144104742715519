import React, { Component } from 'react';
import './pagination.scss';

export default class Pagination extends Component {
    static defaultProps = {
        elementNames: 'item/s',
        onPageChange: () => {}
    };

    buildPagination() {
        let items = [];
        const totalPages = Math.ceil(this.props.totalItems / this.props.itemsPerPage);

        items.push(
            <li key="first">
                <button className="pagination-link"
                        onClick={event => this.props.onPageChange(1)}>Primera</button>
            </li>
        );

        if (this.props.currentPage !== 1) {
            items.push(
                <li key="pre_hellip">
                    <span className="pagination-ellipsis">&hellip;</span>
                </li>
            );
        }

        for (let page = 1; page <= totalPages; page++) {
            items.push(
                <li key={page}>
                    <button className={`pagination-link ${page === this.props.currentPage ? `is-current` : ''}`}
                            onClick={event => this.props.onPageChange(page)}>{page}</button>
                </li>
            );
        }

        items.push(
            <li key="last">
                <button className="pagination-link"
                        onClick={event => this.props.onPageChange(totalPages)}>Última</button>
            </li>
        );

        return {items, totalPages};
    }

    render() {
        const {items, totalPages} = this.buildPagination();

        return (
            <nav className={`pagination ${this.props.className || ''}`} role="navigation" aria-label="pagination">
                <div className="pagination__elements">
                    {totalPages > 1 &&
                        <ul className="pagination-list">
                            {items.map(item => item)}
                        </ul>
                    }
                </div>
                <div className="pagination__totals">
                    Total: {this.props.totalItems} {this.props.elementNames}
                </div>
            </nav>
        );
    }
}