import React from 'react';
import {Navigate} from 'react-router-dom';
import BaseService from '../../services/BaseService';

const PrivateRoute = ({Component, ...props}) => {
    const baseService = new BaseService();

    return baseService.loggedIn()
            ? <Component user={baseService.decodeToken()}/>
            : <Navigate to='/login' state={{from: props.location}}/>
};

export default PrivateRoute;