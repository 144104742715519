import React, {Component} from 'react';
import Icon from '../Icon/Icon';
import Dropzone from 'react-dropzone';
import './file-dropper.scss';

export class FileDropper extends Component {
    static defaultProps = {
        accept: { 'image/jpeg': []},
        multiple: false,
        maxSize: 2097152,
        onDrop: () => {
        }
    };

    onDrop = (files, rejectedFiles) => {
        let sizes = [];
        sizes.push(...files.map(file => file.size));
        sizes.push(...rejectedFiles.map(rejectedFile => rejectedFile.size));

        if (sizes.some(size => size > this.props.maxSize)) {
            alert('¡Se ha superado el tamaño máximo permitido de subida de imágenes de 2Mb!');

            return;
        }

        this.props.onDrop(files, rejectedFiles);
    };

    render() {
        return (
            <div>
                <Dropzone className={'drop-zone'}
                          activeClassName={'drop-zone--is-active'}
                          rejectClassName={'drop-zone--reject'}
                          accept={this.props.accept}
                          multiple={this.props.multiple}
                          maxSize={this.props.maxSize}
                          onDrop={this.onDrop}>
                    {({getRootProps, getInputProps, isDragAccept, isDragReject}) => {
                        let icon = isDragReject ? 'thumbs-down' : 'download';
                        if (isDragAccept) icon = 'thumbs-up';

                        let text = isDragReject ? 'Ese tipo de archivo no está permitido' : 'Elige un archivo o suéltalo aquí';
                        if (isDragAccept) text = '¡Suelta el archivo!';

                        return (
                            <div>
                                <div className={'drop-zone__label'} {...getRootProps()}>
                                    <Icon
                                        icon={icon}/><br/>
                                    <input {...getInputProps()} />
                                    {text}
                                </div>
                            </div>
                        );
                    }}
                </Dropzone>
            </div>
        );
    }
}