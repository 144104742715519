import React, { Component } from 'react';
import ComboBox from '@zippytech/react-toolkit/ComboBox';
import '@zippytech/react-toolkit/ComboBox/index.css';

export default class ComboBoxWrapper extends Component {
    static defaultProps = {
        value: '',
        idProperty: 'id',
        displayProperty: 'label',
        groupProperty: 'type',
        allowCustomTagCreation: false,
        multiple: false,
        autocomplete: true,
        clearIcon: true,
        toggleIcon: false,
        searchable: true,
        toggleExpandOnClick: false,
        expandOnFocus: false,
        expandOnClick: false,
        collapseOnSelect: true,
        listEmptyText: 'No hay resultados…',
        onOptionSelected: () => {}
    };

    handleChange = selected => {
        this.props.onOptionSelected(selected);
    };

    render() {
        return (
            <ComboBox style={{width: '100%'}}
                      value={this.props.value}
                      dataSource={this.props.dataSource}
                      idProperty={this.props.idProperty}
                      groupProperty={this.props.groupProperty}
                      displayProperty={this.props.displayProperty}
                      placeholder={this.props.placeholder}
                      allowCustomTagCreation={this.props.allowCustomTagCreation}
                      multiple={this.props.multiple}
                      autocomplete={this.props.autocomplete}
                      clearIcon={this.props.clearIcon}
                      toggleIcon={this.props.toggleIcon}
                      searchable={this.props.searchable}
                      toggleExpandOnClick={this.props.toggleExpandOnClick}
                      expandOnFocus={this.props.expandOnFocus}
                      expandOnClick={this.props.expandOnClick}
                      collapseOnSelect={this.props.collapseOnSelect}
                      listEmptyText={this.props.listEmptyText}
                      onChange={this.handleChange}
            />
        );
    }
}