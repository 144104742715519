import React, { Component } from 'react';
import './aside-block.scss';

export default class AsideBlock extends Component {
    render() {
        return (
            <section className="aside-block">
                {this.props.title
                    ? <header className="aside-block__header">
                        <h1 className="aside-block__title">{this.props.title}</h1>
                    </header>
                    : null
                }
                {this.props.children}
            </section>
        );
    }
}