import React, { Component } from 'react';
import './toggle.scss';

export default class Toggle extends Component {
    state = {
        checked: this.props.checked
    };

    static defaultProps = {
        type: 'success',
        size: 'regular',
        activeText: 'mostrando solo activos',
        inactiveText: 'mostrando todos',
        onChange: () => {}
    };

    handleChange = event => {
        this.setState({checked: !this.state.checked}, () => this.props.onChange());
    };

    generateClassName() {
        const className = [`toggle-${this.props.size}`, `toggle-${this.props.size}--${this.props.type}`];

        if (this.state.checked)
            className.push(`toggle-${this.props.size}--checked`);

        return className.join(' ');

    }

    render() {
        return (
            <span className={`toggle-wrapper ${this.props.className || ''}`}>
                <span className={this.generateClassName()}
                      onClick={this.handleChange}
                />
                {this.props.children &&
                <label className="toggle-wrapper__text"
                       onClick={this.handleChange}>
                    {this.props.children}
                </label>
                }
            </span>
        );
    }
}